import { Stack, Tooltip, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { ImageLoadingStatus, SliceOrientation, SliceRow } from '../../types';
import { minMaxSliderValues } from '../../utils';

export default function SliceSlider(
    {
        sliceList,
        sliceOrientation,
        sliderValue,
        handleSliderChange,
        loadingStatus
    }: {
        sliceList: SliceRow[],
        sliceOrientation: SliceOrientation,
        sliderValue: number,
        handleSliderChange: (event: Event, newValue: number | number[]) => void,
        loadingStatus: { loading: boolean, percentDownloaded: number, status: ImageLoadingStatus }
    }
) {
    const minMax = minMaxSliderValues(sliceList)
    const positionUnit = sliceOrientation === SliceOrientation.AXIAL ? " deg" : " mm"
    const sliderValues = sliceList.map((slice: SliceRow) =>
    ({
        value: slice.position,
        label: null,
    }))

    const sliderDisabled = loadingStatus.loading


    const loadingStatusMessage = (status: ImageLoadingStatus, percentDownloaded: number) => {
        switch (status) {
            case ImageLoadingStatus.SERVER_COMPILING_ZIP:
                return <span>{["Compiling remaining slices.", <br />, "Slider will be enabled when all slices are loaded."]}</span>
            case ImageLoadingStatus.DOWNLOADING_ZIP:
                return <span>{["Downloading remaining slices: " + percentDownloaded + "%", <br />,
                    "Slider will be enabled when all slices are loaded."]}</span>
            case ImageLoadingStatus.ERROR:
                return "Something went wrong! please try reloading"
            default:
                return ""
        }
    }

    return <Tooltip
        title={loadingStatusMessage(loadingStatus.status, loadingStatus.percentDownloaded)}
        placement="right"
        followCursor={true}
    >
        <Stack
            spacing={2}
            sx={{ pt: 1, pb: 1, pl: 1, height: '100%' }}
            justifyContent="center"
            justifyItems={"center"}
            alignItems={"center"}
        >
            <Typography variant='body2'>{minMax.max + positionUnit}</Typography>
            <Slider
                data-testid="slice_slider"
                orientation='vertical'
                min={minMax.min}
                max={minMax.max}
                marks={sliderValues}
                step={null}
                track={false}
                valueLabelDisplay="on"
                value={typeof sliderValue === 'number' ? sliderValue : 0}
                onChange={handleSliderChange}
                disabled={sliderDisabled}
                sx={{
                    pl: 8,
                    flexGrow: 1,
                    '& .MuiSlider-thumb': {
                        height: 4,
                        width: 15,
                        borderRadius: '3px',
                    },
                    '& .MuiSlider-valueLabel': {
                        backgroundColor: 'unset',
                        padding: '0px',
                        margin: '0px',
                    }
                }}
            />
            <Typography variant='body2'>{minMax.min + positionUnit}</Typography>
        </Stack>
    </Tooltip>
}
