import { Divider, Grid, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { loadAsync } from 'jszip';
import { useEffect, useRef, useState } from "react";
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import SomethingWentWrongPage from '../../pages/SomethingWentWrongPage';
import TooManyRequestsPage from '../../pages/TooManyRequestsPage';
import {
  ImageLoadingStatus,
  ManualMeasurementResults,
  MeasurementDimensions,
  MeasurementStatus,
  RawCathodeTraceResults,
  RawElectrodeOverhangTraceResults,
  RawGeneralInspectionResults,
  ScanList,
  SliceImageObject, SliceOrientation, SlicePositionsCyl, SlicePositionsXyz, SliceRow
} from '../../types';
import { ApiEndpoints, ParamsType } from '../../utils/apiUtils';
import { AutomatedInspectionMetrics, getIdFromInternalName } from '../../utils/inspection';
import { getListOfShapes } from '../../utils/inspection/inspectionShapes';
import { getAutomatedInspectionTableData, StatsIds } from '../../utils/inspection/inspectionTableData';
import BasicLoadingIndicator from '../BasicLoadingIndicator';
import AutomatedMeasurementList, { AutomatedInspectionTableData } from './AutomatedMeasuremetList';
import ManualMeasurementList from './ManualMeasurementList';
import SliceImage from "./SliceImage";
import SliceSlider from './SliceSlider';



export default function OneAxisSliceView(
  {
    sliceList,
    sliceOrientation,
    scanData,
    handleSliderChange,
    slicePositions,
    minSlicePositions,
    isDemoMode,
    availableMetrics,
    toggleScanContext
  }: {
    sliceList: SliceRow[],
    sliceOrientation: SliceOrientation,
    scanData: ScanList,
    handleSliderChange: (event: Event, newValue: number | number[]) => void,
    slicePositions: SlicePositionsCyl | SlicePositionsXyz
    minSlicePositions: SlicePositionsCyl | SlicePositionsXyz,
    isDemoMode: boolean,
    availableMetrics: MeasurementDimensions[],
    toggleScanContext: () => void
  }
) {

  const { fetchData } = useAuthTokenAndAccessApi();
  const [sliderValue, setSliderValue] = useState(0);
  const [visibleImage, setVisibleImage] = useState("")
  const [visibleSliceData, setVisibleSliceData] = useState({} as SliceRow)
  const [sliceImages, setSlicesImages] = useState<SliceImageObject[]>([])
  const [thumbnailLoadingStatus, setThumbnailLoadingStatus] = useState(true)
  const [loadingStatus, setLoadingStatus] = useState({ loading: true, percentDownloaded: 0, status: ImageLoadingStatus.IDLE })
  const [imageViewerDimensions, setImageViewerDimensions] = useState({ width: 0, height: 0 })
  const [rawImageDimensions, setRawImageDimensions] = useState({ height: 0, width: 0 });

  // Manual Inspection State: is presently handled differently than automated.
  const [manualMeasurements, setManualMeasurements] = useState([] as ManualMeasurementResults[]);

  // Automated Inspection State:
  const [rawAutomatedInspectionResults, setRawAutomatedInspectionResults] = useState([] as RawGeneralInspectionResults[]) // raw general state

  const [rawCathodeTraceResults, setRawCathodeTraceResults] = useState([] as RawCathodeTraceResults[])
  const [rawElectrodeOverhangTraceResults, setRawElectrodeOverhangTraceResults] = useState([] as RawElectrodeOverhangTraceResults[])

  // GOTCHA: vvv this had to be state to kill a repeated render bug vvv
  const [inspectionTableItems, setInspectionTableItems] = useState<AutomatedInspectionTableData[]>([])
  // GOTCHA: vvv this is required to keep inspection data snappy when flipping slices. Computing this lead to bugs vvv
  const [visibleInspectionMetricIds, setVisibleInspectionMetricIds] = useState([] as number[])

  const [highlightedMeasurement, setHighlightedMeasurement] = useState({ id: -1, source: "manual" } as { id: number, source: "manual" | "automated" });
  const [metrologyLoadingStatus, setMetrologyLoadingStatus] = useState(true)
  const [measurementStatus, setMeasurementStatus] = useState(MeasurementStatus.COMPLETE);

  const panelHeight = 475
  const rightPanelWidth = 90
  const bottomPanelHeight = 300

  const ref = useRef<HTMLDivElement>(null);

  const getCurrentSlice = (): SliceRow => {
    // This is all to appease the type checker, I code golfed this a bit, not super readable.
    // but just makes sure we pull the sliceList object that is of the right orientation.
    let newValue = 0;
    if (sliceOrientation === SliceOrientation.AXIAL || sliceOrientation === SliceOrientation.RADIAL) {
      const cylPositions = slicePositions as SlicePositionsCyl;
      newValue = cylPositions[sliceOrientation];
    } else if (
      sliceOrientation === SliceOrientation.XY ||
      sliceOrientation === SliceOrientation.XZ ||
      sliceOrientation === SliceOrientation.YZ
    ) {
      const xyzPositions = slicePositions as SlicePositionsXyz;
      newValue = xyzPositions[sliceOrientation];
    }

    return sliceList.find((e) => e.position === newValue) || ({} as SliceRow);
  };


  useEffect(() => {
    coordinateData()
    setDefaultMetrics()
    return () => { sliceImages.forEach((e) => URL.revokeObjectURL(e.image)) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanData.scan_id])

  async function coordinateData() {
    getInspectionResults(isDemoMode, scanData.scan_id, sliceOrientation)
    await getFirstThumbnailImage(getCurrentSlice().slice_id)
      .then(() => getImagesAsByteArray(scanData.scan_id, sliceOrientation))
  }

  const setDefaultMetrics = () => {
    const defaultMetricsOn = [
      AutomatedInspectionMetrics.CORE_AREA,
      AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
      AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
      AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
    ]
    const defaultMetricIds = availableMetrics
      .filter((e) => e.orientation === sliceOrientation)
      .filter((e) => defaultMetricsOn.includes(e.metric_internal_name as AutomatedInspectionMetrics))
      .map((e) => e.metric_id)
    const anodeOverhangId = getIdFromInternalName(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL, availableMetrics)
    const anodeOverhangSubIds = [StatsIds.MAX, StatsIds.MIN, StatsIds.MEAN, StatsIds.STD_DEV].map((e) => anodeOverhangId * 1000 + e)
    setVisibleInspectionMetricIds([...defaultMetricIds, ...anodeOverhangSubIds])
  }


  // TODO: may need to raise this state up, so we pass down the imageViewerDimensions as prop
  const handleResize = (
    ref: React.RefObject<HTMLDivElement>,
    rightPanelWidth: number,
    setImageViewerDimensions: React.Dispatch<React.SetStateAction<{ width: number; height: number }>>
  ) => {

    if (ref.current) {
      const width = ref.current.offsetWidth - 10 - rightPanelWidth;
      const height = ref.current.offsetHeight;
      if (width && height) {
        setImageViewerDimensions({ width: Math.round(width), height: Math.round(height) });
      }
    }
  };

  useEffect(() => {
    const resizeHandler = () => handleResize(ref, rightPanelWidth, setImageViewerDimensions);
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [rightPanelWidth, toggleScanContext]);


  async function getInspectionResults(isDemoMode: boolean, scan_id: number, orientation: SliceOrientation) {
    let params = { scan_id: scan_id } as ParamsType
    if (isDemoMode) params = { ...params, is_demo: true }
    switch (orientation) {
      case SliceOrientation.RADIAL:
        try {
          const responseInspectionResults = await fetchData(ApiEndpoints.INSPECTION_RESULTS_GENERAL, params)
          setRawAutomatedInspectionResults(responseInspectionResults.data)
          setMetrologyLoadingStatus(false)
        }
        catch (error: any) { console.error(error) }
        break;
      case SliceOrientation.AXIAL:
        try {
          const responseInspectionResults = await fetchData(ApiEndpoints.INSPECTION_RESULTS_GENERAL, params)
          setRawAutomatedInspectionResults(responseInspectionResults.data)

          const responseCathode = await fetchData(ApiEndpoints.INSPECTION_RESULTS_AXIAL_CATHODE_TRACE, params)
          setRawCathodeTraceResults(responseCathode.data)

          const responseElectrode = await fetchData(ApiEndpoints.INSPECTION_RESULTS_AXIAL_ELECTRODE_OVERHANG_TRACE, params)
          setRawElectrodeOverhangTraceResults(responseElectrode.data)

          setMetrologyLoadingStatus(false)
        }
        catch (error: any) { console.error(error) }
        break;
      case SliceOrientation.XY:
      case SliceOrientation.XZ:
      case SliceOrientation.YZ:
      default:
        setMetrologyLoadingStatus(false)
    }
  }

  useEffect(() => {
    const slice_id = sliceList.find((e) => e.position === sliderValue)?.slice_id
    const tableData = getAutomatedInspectionTableData(
      rawAutomatedInspectionResults,
      slice_id,
      visibleInspectionMetricIds,
      availableMetrics,
      sliceOrientation,
      rawCathodeTraceResults,
      rawElectrodeOverhangTraceResults,
    )
    setInspectionTableItems(tableData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rawAutomatedInspectionResults,
    sliderValue,
    visibleInspectionMetricIds,
    rawCathodeTraceResults,
    rawElectrodeOverhangTraceResults,
    loadingStatus
  ])

  async function getImageDimensions(imageUrl: string): Promise<{ height: number; width: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        resolve({ height: img.height, width: img.width });
      };
    });
  }

  async function getFirstThumbnailImage(slice_id: number) {
    let params: any = isDemoMode ? { is_demo: true } : {}
    params = { ...params, slice_id: slice_id };

    await fetchData(ApiEndpoints.SLICE_IMAGE_PNG, params, {
      responseType: 'arraybuffer'
    })
      .then((response) => {
        const imageResponse: ArrayBuffer = response.data;
        const contentType = response.headers['content-type']; // Get the content type from response headers

        let imageType = "image/png";
        if (contentType === "image/avif") {
          imageType = "image/avif";
        }
        const imageBlob = new Blob([imageResponse], { type: imageType });
        const imageUrl = URL.createObjectURL(imageBlob);
        return imageUrl
      })
      .then(async (imageUrl) => {
        const dimensions = await getImageDimensions(imageUrl)
        setRawImageDimensions(dimensions);
        return imageUrl
      })
      .then((imageUrl) => {
        const imageList = [{ slice_id: slice_id, image: imageUrl }]
        setSlicesImages(imageList);
        setThumbnailLoadingStatus(false)
      })
  }

  async function getImagesAsByteArray(scan_id: number, orientation: SliceOrientation) {
    setLoadingStatus({ loading: true, percentDownloaded: 0, status: ImageLoadingStatus.SERVER_COMPILING_ZIP })
    try {
      const endpoint = isDemoMode ? ApiEndpoints.DEMO_SCAN_IMAGES_ZIP : ApiEndpoints.SCAN_IMAGES_ZIP
      const params = { scan_id: scan_id, orientation: orientation } as ParamsType
      const response = await fetchData(endpoint, params, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setLoadingStatus({ loading: true, percentDownloaded: percentCompleted, status: ImageLoadingStatus.DOWNLOADING_ZIP })
          }
        }
      });

      const zipData = new Uint8Array(response.data);
      const zip = await loadAsync(zipData);
      let imageList = [] as SliceImageObject[]
      await Promise.all(Object.keys(zip.files).map(async (filename) => {
        const file = zip.files[filename];
        const image = await file.async('uint8array');
        let imageType = "image/png"
        if (file.name.includes(".avif")) imageType = "image/avif"
        const imageBlob = new Blob([image], { 'type': imageType });
        const sliceId = parseInt(file.name.replace('.avif', ''));
        imageList.push({ slice_id: sliceId, image: URL.createObjectURL(imageBlob) })
      }))
        .then(() => getImageDimensions(imageList[0].image))
        .then((dimensions) => {
          setRawImageDimensions(dimensions);
        })
        .then(() => {
          setSlicesImages(imageList);
        })
        .then(() => {
          handleResize(ref, rightPanelWidth, setImageViewerDimensions);
        })
        .then(() => {
          setLoadingStatus({ loading: false, percentDownloaded: 100, status: ImageLoadingStatus.SUCCESS }); // Update loading status
        });
    } catch (error: any) {
      if (error.response && error.response.status === 429) {
        setLoadingStatus({ loading: false, percentDownloaded: 0, status: ImageLoadingStatus.TOO_MANY_REQUESTS })
      } else
        setLoadingStatus({ loading: false, percentDownloaded: 0, status: ImageLoadingStatus.ERROR })
    }
  }

  useEffect(() => {
    const currentSlice = getCurrentSlice();
    const new_slice_id = currentSlice?.slice_id;
    const visibleImage = sliceImages.find((e) => e.slice_id === new_slice_id)?.image || "";
    const visibleSliceData = currentSlice;

    setSliderValue(currentSlice?.position || 0);
    setVisibleImage(visibleImage);
    setVisibleSliceData(visibleSliceData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceImages, slicePositions]);

  const removeMeasurement = (id: number) => {
    setManualMeasurements(manualMeasurements.filter(e => e.id !== id));

  }


  const layoutCard = () => {
    const rawHeight = rawImageDimensions.height
    const rawWidth = rawImageDimensions.width
    if (loadingStatus.status === ImageLoadingStatus.ERROR) return <SomethingWentWrongPage />
    if (loadingStatus.status === ImageLoadingStatus.TOO_MANY_REQUESTS) return <TooManyRequestsPage />
    if (thumbnailLoadingStatus) return <BasicLoadingIndicator message={""} />

    const padPercent = 0.4
    let imageMeasurementPadding = { horizontal: rawWidth * padPercent, vertical: rawHeight * padPercent };
    if (rawHeight / rawWidth >= 1) {
      imageMeasurementPadding.horizontal = (rawHeight / 2 * (1 + padPercent)) - (rawWidth / 2)
    }
    if (rawHeight / rawWidth < 1) {
      imageMeasurementPadding.vertical = (rawWidth / 2 * (1 + padPercent)) - (rawHeight / 2)
    }
    const defaultScale = Math.min(imageViewerDimensions.height / rawHeight,
      imageViewerDimensions.width / rawWidth) / 1.05
    const initialPosition = {
      x: imageViewerDimensions.width / 2 - rawWidth * defaultScale / 2 - imageMeasurementPadding.horizontal * defaultScale,
      y: imageViewerDimensions.height / 2 - rawHeight * defaultScale / 2 - imageMeasurementPadding.vertical * defaultScale
    }
    const transformWrapperProps = {
      initialScale: defaultScale,
      minScale: defaultScale / 1.3,
      initialPositionX: Math.round(initialPosition.x * 1),
      initialPositionY: Math.round(initialPosition.y * 1)
    };

    if (visibleImage !== "" && transformWrapperProps.initialScale !== 0) return (
      <SliceImage
        visibleImage={visibleImage}
        scanData={scanData}
        panelWidth={imageViewerDimensions.width}
        panelHeight={imageViewerDimensions.height}
        visibleSliceData={visibleSliceData}
        slicePositions={slicePositions}
        sliceOrientation={sliceOrientation}
        minSlicePositions={minSlicePositions}
        // needed for the image display:
        transformWrapperProps={transformWrapperProps}
        imageDimensions={{ height: rawHeight, width: rawWidth }}
        imageMeasurementPadding={imageMeasurementPadding}
        // this is for the click event to send action up:
        updateManualMeasurements={setManualMeasurements}
        annotationList={getListOfShapes(
          manualMeasurements,
          rawAutomatedInspectionResults,
          imageMeasurementPadding,
          availableMetrics,
          highlightedMeasurement,
          visibleSliceData.slice_id,
          visibleInspectionMetricIds,
          rawCathodeTraceResults,
          rawElectrodeOverhangTraceResults,
        )}
        allMeasurements={manualMeasurements.filter((e) => e.display)} // Needed for measurement
        measurementStatus={measurementStatus}
        setMeasurementStatus={setMeasurementStatus}
      />
    )
    return null
  }

  const toggleAllHandler = (allOnOrOff: boolean) => {
    allOnOrOff ? setVisibleInspectionMetricIds(inspectionTableItems.map(e => e.id)) : setVisibleInspectionMetricIds([])
  }

  const toggleGroupHandler = (groupId: number) => {
    const groupMetrics = inspectionTableItems.filter((e) => e.group_id === groupId).map((e) => e.id)
    const allOnOrOff = groupMetrics.every((e) => visibleInspectionMetricIds.includes(e))
    allOnOrOff ? setVisibleInspectionMetricIds(visibleInspectionMetricIds.filter((e) => !groupMetrics.includes(e))) : setVisibleInspectionMetricIds([...visibleInspectionMetricIds, ...groupMetrics])
  }

  const toggleOne = (id: number) => {
    // Stopgap fix for max/min/mean: toggle all on or off
    if (id > 10000) {
      const groupId = inspectionTableItems.find((e) => e.id === id)?.group_id
      const idsToToggle = inspectionTableItems.filter((e) => e.group_id === groupId).map((e) => e.id)
      visibleInspectionMetricIds.includes(id) ?
        setVisibleInspectionMetricIds(visibleInspectionMetricIds.filter((e) => !idsToToggle.includes(e))) :
        setVisibleInspectionMetricIds([...visibleInspectionMetricIds, ...idsToToggle])
    }
    else {
      // usual toggle case:
      visibleInspectionMetricIds.includes(id) ?
        setVisibleInspectionMetricIds(visibleInspectionMetricIds.filter((e) => e !== id)) :
        setVisibleInspectionMetricIds([...visibleInspectionMetricIds, id])
    }
  }

  return (
    <>
      <Typography variant="body1" sx={{ pt: 1, pb: 0.5 }}>
        {sliceOrientation.toUpperCase()}
      </Typography>
      <Card variant='outlined' ref={ref} sx={{
        borderRadius: '0%',
        height: panelHeight
      }}>
        <Grid container sx={{ height: '100%' }} >
          <Grid item xs>
            {layoutCard()}
          </Grid>
          <Grid item sx={{ width: rightPanelWidth }}>
            <SliceSlider
              sliceList={sliceList}
              sliceOrientation={sliceOrientation}
              sliderValue={sliderValue}
              handleSliderChange={handleSliderChange}
              loadingStatus={loadingStatus}
            />
          </Grid>
        </Grid>
      </Card >
      <Card variant='outlined' sx={{ borderRadius: '0%', height: bottomPanelHeight, }}>
        <Stack direction='row' >
          <ManualMeasurementList
            measurementList={manualMeasurements}
            boxHeight={bottomPanelHeight}
            setHighlightedMeasurement={(id) => setHighlightedMeasurement({ id: id, source: "manual" })}
            toggleDisplay={(id) => setManualMeasurements(manualMeasurements.map(measurement => measurement.id === id ?
              { ...measurement, display: !measurement.display } :
              measurement))}
            removeMeasurement={removeMeasurement}
            measurementStatus={measurementStatus}
          />
          <Divider orientation="vertical" flexItem />
          <AutomatedMeasurementList
            measurementList={inspectionTableItems}
            boxHeight={bottomPanelHeight}
            setHighlightedMeasurement={(id) => setHighlightedMeasurement({ id: id, source: "automated" })}
            toggleDisplay={toggleOne}
            toggleGroup={toggleGroupHandler}
            toggleAll={toggleAllHandler}
            loadingStatus={metrologyLoadingStatus}
          />
        </Stack>
      </Card>
    </>
  );
}
