import { Button, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import { OperatorGlimpseboxList } from '../../types';
import { ApiEndpoints } from '../../utils/apiUtils';
import GlimpseboxSelector from './GlimpseboxSelector';

interface RequestsAvailable {
    id: number;
    name: string;
}

export default function BatchSelectionTool(
    {
        selectedGlimpsebox,
        availableGlimpseboxes,
        handleGlimpseboxChange
    }: {
        selectedGlimpsebox: OperatorGlimpseboxList,
        availableGlimpseboxes: OperatorGlimpseboxList[],
        handleGlimpseboxChange: (glimpsebox: OperatorGlimpseboxList) => void
    }
) {
    const { fetchData, putData } = useAuthTokenAndAccessApi()
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [activeRequest, setActiveRequest] = useState({ active_request_id: 0, active_request_name: "" });
    const [requestsAvailable, setRequestsAvailable] = useState([] as RequestsAvailable[]);
    const [selectedRequestId, setSelectedRequestId] = useState(0);

    useEffect(() => { getGlimpseboxInfo() }, [selectedGlimpsebox]);


    const getGlimpseboxInfo = async () => {
        setLoadingStatus(true);
        const activeRequestResponse = await fetchData(ApiEndpoints.OPERATOR_GLIMPSEBOX_ACTIVE_REQUEST, { glimpsebox_user_id: selectedGlimpsebox.glimpsebox_user_id });
        const activeRequest = activeRequestResponse.data;
        setActiveRequest({
            active_request_id: activeRequest.active_request_id,
            active_request_name: activeRequest.active_request_name
        });

        const requestsAvailable = await fetchData(ApiEndpoints.OPERATOR_REQUESTS_AVAILABLE_TO_GLIMPSEBOX, { glimpsebox_user_id: selectedGlimpsebox.glimpsebox_user_id });
        const requestsTyped = requestsAvailable.data as RequestsAvailable[];
        setRequestsAvailable(requestsTyped.sort((a, b) => b.id - a.id));
        setLoadingStatus(false);
    }

    const updateActiveRequest = async (requestId: number) => {
        // PUT one id
        const params = {
            glimpsebox_user_id: selectedGlimpsebox.glimpsebox_user_id,
            request_id: requestId
        }
        await putData(ApiEndpoints.OPERATOR_GLIMPSEBOX_ACTIVE_REQUEST, null, params).then(() => {
            getGlimpseboxInfo()
        })
    }

    const nameAndIdToString = (request_name: string, request_id: number) => {
        return request_name + " (id: " + request_id + ")"
    }

    return (
        <>
            <Card sx={{ padding: 2, marginBottom: 2, width: 600 }}>
                <Typography variant="h5">Batch Selection</Typography>

                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: "center" }}
                >
                    <Typography variant="body1">Selecting for Glimpsebox: </Typography>
                    <GlimpseboxSelector
                        selectedGlimpsebox={selectedGlimpsebox}
                        glimpseboxes={availableGlimpseboxes}
                        handleGlimpseboxChange={handleGlimpseboxChange}
                    />
                </Stack>
                {loadingStatus && loadingStatus ? <BasicLoadingIndicator message='Loading' /> :
                    <>
                        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>Current Batch: <u>{
                            nameAndIdToString(activeRequest.active_request_name, activeRequest.active_request_id)
                        }</u></Typography>
                        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>Update batch to: </Typography>
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel >
                                Available Batches
                            </InputLabel>
                            <Select
                                required={false}
                                value={selectedRequestId === 0 ? '' : selectedRequestId.toString()}
                                onChange={(e) => setSelectedRequestId(parseInt(e.target.value))}
                                label="Available Batches"
                                sx={{ width: 500 }}
                            >
                                {requestsAvailable.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                        {nameAndIdToString(e.name, e.id)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br></br>
                        <Button variant='outlined' sx={{ m: 1 }} onClick={() => updateActiveRequest(selectedRequestId)}>
                            Update
                        </Button>
                    </>
                }
            </Card>

        </>
    );
}
